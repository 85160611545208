import { createApp } from 'vue';
import App from './App.vue';
import PrimeVue from 'primevue/config';
import PrimeButton from 'primevue/button';
import PrimeInputText from 'primevue/inputtext';
import PrimeDropdown from 'primevue/dropdown';
import PrimeRadioButton from 'primevue/radiobutton';
import PrimeCalendar from 'primevue/calendar';
import PrimeSelectButton from 'primevue/selectbutton';
import PrimeCheckbox from 'primevue/checkbox';
import PrimeSteps from 'primevue/steps';


import PrimeDialog from 'primevue/dialog';
import ConfirmDialog from 'primevue/confirmdialog';

import FileUpload from 'primevue/fileupload';
import ConfirmationService from 'primevue/confirmationservice';

import PrimeToast from 'primevue/toast';
import ToastService from 'primevue/toastservice';



import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import '/node_modules/primeflex/primeflex.css'






const app = createApp(App);

app.use(PrimeVue);

app.component('PrimeButton', PrimeButton);
app.component('PrimeInputText', PrimeInputText);
app.component('PrimeDropdown', PrimeDropdown);
app.component('PrimeRadioButton', PrimeRadioButton);
app.component('PrimeCalendar', PrimeCalendar);
app.component('PrimeSelectButton', PrimeSelectButton);
app.component('PrimeCheckbox', PrimeCheckbox);
app.component('PrimeSteps', PrimeSteps);
app.component('FileUpload', FileUpload);
app.component('PrimeDialog', PrimeDialog);
app.component('ConfirmDialog', ConfirmDialog);
app.component('PrimeToast', PrimeToast);
app.use(ConfirmationService);
app.use(ToastService);
app.mount('#app');
