<template>
  <PrimeToast />
  <!--<ConfirmDialog></ConfirmDialog> -->

  <!-- Success Dialog -->
  <PrimeDialog header="Success" v-model:visible="showSuccessDialog" modal>
    <p>Form submitted successfully!</p>
    <template #footer>
      <PrimeButton label="OK" icon="pi pi-check" @click="submitRedirect" />
    </template>
  </PrimeDialog>

  <!-- Error Dialog -->
  <PrimeDialog header="Error" v-model:visible="showErrorDialog" modal>
    <p>{{ errorMessage }}</p>
    <template #footer>
      <PrimeButton label="OK" icon="pi pi-times" @click="showErrorDialog = false" />
    </template>
  </PrimeDialog>

  <!-- Error Dialog -->
  <PrimeDialog header="Verify Later?" v-model:visible="showVerifyLater" modal>
    <p>{{ verifyLaterMessage }}</p>
    <template #footer>
      <PrimeButton label="Yes" icon="pi pi-check" severity="success"  @click="verifyLater" />
      <PrimeButton label="Retry" icon="pi pi-replay" @click="retryCapture"  />
    </template>
  </PrimeDialog>


  <div class="container ">

    <div class="row justify-content-center align-items-center h-100 w-full">
      <img class="p-2" width="150" src="@/assets/jcs-logo.svg" alt="" srcset="">
      <img class="p-2" width="150" src="@/assets/uba2.png" alt="" srcset="">
    </div>
    <h2 class=" text-white  p-1">JCS Green Card Form</h2>
    <p class="pb-1">All fields marked <b><span class="required">*</span></b> are mandatory</p>

    <!-- <div class="card flex flex-wrap gap-2 justify-content-center">
        <PrimeButton @click="confirm1()" label="OK" outlined></PrimeButton>
        <PrimeButton @click="confirm2()" label="Delete" severity="danger" outlined></PrimeButton>
    </div> -->
    <form @submit.prevent="handleSubmit" class="form-container">
      <!-- Step One -->
      <div v-if="currentStep === 0" class="form-step">
        <h3>Step 1 of 4 - Applicant Information</h3>


        <div :class="{ error: v$.formData.photo.$errors.length }"></div>

        <label for="picture" class="form-label">Upload Picture <span class="required">*</span></label>

        <FileUpload :show-cancel-button="false" :show-upload-button="false" name="demo[]" url="/api/upload"
          @select="onFileSelect" :multiple="false" :auto="true" accept="image/*" :maxFileSize="1000000">
          <!-- <template #empty>
            <p>Upload Photo.</p>
          </template> -->
        </FileUpload>
        <div class="input-errors" v-for="error of v$.formData.photo.$errors" :key="error.$uid">
          <div class="error-msg">{{ error.$message }}</div>
        </div>



        <div class="formgrid grid">

          <div class="field col-12 md:col-4">
            <div :class="{ error: v$.formData.title.$errors.length }"> </div>

            <label for="title" class="form-label">Title <span class="required">*</span></label>
            <PrimeDropdown class="w-full" v-model="formData.title" :options="titles" optionLabel="label"
              optionValue="value" placeholder="Select Title" />
            <div class="input-errors" v-for="error of v$.formData.title.$errors" :key="error.$uid">
              <div class="error-msg">{{ error.$message }}</div>
            </div>

          </div>

          <div class="field col-12 md:col-6">
            <div :class="{ error: v$.formData.gender.$errors.length }"></div>
            <label for="gender">Gender <span class="required">*</span></label>

            <div class="flex flex-wrap gap-3">
              <div class="flex ">
                <PrimeRadioButton v-model="formData.gender" inputId="gender1" name="gender" value="Male" />
                <label for="ingredient2" class="ml-2">Male</label>
              </div>
              <div class="flex ">
                <PrimeRadioButton v-model="formData.gender" inputId="gender2" name="gender" value="Female" />
                <label for="ingredient3" class="ml-2">Female</label>
              </div>
            </div>
            <div class="input-errors" v-for="error of v$.formData.gender.$errors" :key="error.$uid">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>

          <div class="field col-12 md:col-4">
            <div :class="{ error: v$.formData.firstname.$errors.length }"> </div>
            <!-- <input v-model="state.firstName"> -->
            <label for="firstname">First Name <span class="required">*</span></label>
            <PrimeInputText v-model="formData.firstname" class="w-full" id="firstname" />
            <!-- <PrimeInputText v-model="formData.surname" class="w-full" id="surname" /> -->
            <div class="input-errors" v-for="error of v$.formData.firstname.$errors" :key="error.$uid">
              <div class="error-msg">{{ error.$message }}</div>
            </div>

            <!-- <PrimeInputText v-model="formData.firstname" class="w-full" id="firstname" /> -->
          </div>


          <div class="field col-12 md:col-4">
            <!-- <div :class="{ error: v$.formData.middle.$errors.length }"></div> -->
            <label for="middlename">Middle Name</label>
            <PrimeInputText v-model="formData.middlename" class="w-full" id="middlename" />
            <!-- <div class="input-errors" v-for="error of v$.formData.firstname.$errors" :key="error.$uid">
              <div class="error-msg">{{ error.$message }}</div>
            </div> -->
          </div>
          <div class="field col-12 md:col-4">
            <div :class="{ error: v$.formData.surname.$errors.length }"></div>
            <label for="surname">Surname <span class="required">*</span></label>
            <PrimeInputText v-model="formData.surname" class="w-full" id="surname" />
            <div class="input-errors" v-for="error of v$.formData.surname.$errors" :key="error.$uid">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
          <div class="field col-12 md:col-6">
            <div :class="{ error: v$.formData.residential_address.$errors.length }"></div>

            <label for="residential_address">Residential Address <span class="required">*</span></label>
            <PrimeInputText v-model="formData.residential_address" class="w-full" id="residential_address" />
            <div class="input-errors" v-for="error of v$.formData.residential_address.$errors" :key="error.$uid">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
          <div class="field col-12 md:col-6">
            <div :class="{ error: v$.formData.digital_address.$errors.length }"></div>
            <label for="digital_address">Digital Address <span class="required">*</span></label>
            <PrimeInputText v-model="formData.digital_address" class="w-full" id="digital_address" />
            <div class="input-errors" v-for="error of v$.formData.digital_address.$errors" :key="error.$uid">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
          <div class="field col-12 md:col-4">
            <div :class="{ error: v$.formData.city.$errors.length }"></div>
            <label for="city">City <span class="required">*</span></label>
            <PrimeInputText v-model="formData.city" class="w-full" id="city" />
            <div class="input-errors" v-for="error of v$.formData.city.$errors" :key="error.$uid">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
          <div class="field col-12 md:col-4">

            <div :class="{ error: v$.formData.country.$errors.length }"></div>
            <label for="country">Country <span class="required">*</span></label>
            <!-- <PrimeInputText v-model="formData.country" class="w-full" id="country" /> -->
            <PrimeDropdown v-model="formData.country" :options="countries" optionValue="name" optionLabel="name"
              placeholder="Select a Country" class="w-full " />

            <div class="input-errors" v-for="error of v$.formData.country.$errors" :key="error.$uid">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
          <div class="field col-12 md:col-4">
            <div :class="{ error: v$.formData.nationality.$errors.length }"></div>
            <label for="nationality">Nationality <span class="required">*</span></label>
            <PrimeInputText v-model="formData.nationality" class="w-full" id="nationality" />
            <div class="input-errors" v-for="error of v$.formData.nationality.$errors" :key="error.$uid">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>

          <div class="field col-12 md:col-4">
            <div :class="{ error: v$.formData.date_of_birth.$errors.length }"></div>
            <label for="date_of_birth" class="ml-2">Date of Birth <span class="required">*</span></label>
            <PrimeCalendar :maxDate="calculateMaxDate" id="date_of_birth" class="w-full"
              v-model="formData.date_of_birth" showIcon iconDisplay="input" />
            <div class="input-errors" v-for="error of v$.formData.date_of_birth.$errors" :key="error.$uid">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>

          <div class="field col-12 md:col-4">
            <!-- <div :class="{ error: v$.formData.place_of_birth.$errors.length }"></div> -->
            <label for="place_of_birth">Place of Birth</label>
            <PrimeInputText v-model="formData.place_of_birth" class="w-full" id="place_of_birth" />
            <!-- <div class="input-errors" v-for="error of v$.formData.place_of_birth.$errors" :key="error.$uid">
              <div class="error-msg">{{ error.$message }}</div>
            </div> -->
          </div>
          <div class="field col-12 md:col-4">
            <div :class="{ error: v$.formData.email.$errors.length }"></div>
            <label for="email">Email Address <span class="required">*</span></label>
            <PrimeInputText v-model="formData.email" class="w-full" id="email" />
            <div class="input-errors" v-for="error of v$.formData.email.$errors" :key="error.$uid">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
          <div class="field col-12 md:col-6">
            <div :class="{ error: v$.formData.mobile.$errors.length }"></div>
            <label for="mobile">Mobile <span class="required">*</span></label>
            <PrimeInputText v-model="formData.mobile" class="w-full"  @blur="v$.formData.mobile.$touch()" id="mobile" />
            <div class="input-errors" v-for="error of v$.formData.mobile.$errors" :key="error.$uid">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>
          <div class="field col-12 md:col-6">
            <!-- <div :class="{ error: v$.formData.telephone.$errors.length }"></div> -->
            <label for="telephone">Telephone</label>
            <PrimeInputText v-model="formData.telephone" class="w-full" id="telephone" />
            <!-- <div class="input-errors" v-for="error of v$.formData.telephone.$errors" :key="error.$uid">
              <div class="error-msg">{{ error.$message }}</div>
            </div> -->
          </div>



          <div class="field col-12 md:col-8">
            <!-- <div :class="{ error: v$.formData.uba_customer.$errors.length }"></div> -->
            <label for="identification_type">Type of Identification <span class="required">*</span></label>
            <div class="w-full ">
              <PrimeRadioButton class="ml-2" @click="nationalIDClick" v-model="formData.identification_type"
                inputId="identification_type1" name="nationalID" value="National ID" />
              <label for="nationalID" class="ml-2">National ID</label>

              <PrimeRadioButton @click="passportClick" class="ml-2" v-model="formData.identification_type"
                inputId="identification_type2" name="passport" value="Passport" />
              <label for="not" class="ml-2">Passport (Foreign Nationals)</label>

              <PrimeRadioButton class="ml-2" v-model="formData.identification_type" inputId="identification_type2"
                name="passport" value="Others" />
              <label for="not" class="ml-2">Others</label>
            </div>
            <!-- <div class="input-errors" v-for="error of v$.formData.uba_customer.$errors" :key="error.$uid">
              <div class="error-msg">{{ error.$message }}</div>
            </div> -->
          </div>
          <div class="field col-12 md:col-6">
            <div :class="{ error: v$.formData.identification_number.$errors.length }"></div>
            <!-- <input v-model="state.firstName"> -->
            <label for="identification_number" class="m-2">Identification Number <span class="required">*</span></label>
            <PrimeInputText v-model="formData.identification_number" class="w-full" id="identification_number" />
            <!-- <PrimeInputText v-model="formData.surname" class="w-full" id="surname" /> -->
            <div class="input-errors" v-for="error of v$.formData.identification_number.$errors" :key="error.$uid">
              <div class="error-msg">{{ error.$message }}</div>
            </div>

            <!-- <PrimeInputText v-model="formData.firstname" class="w-full" id="firstname" /> -->
          </div>

          <div class="field col-12 md:col-6">
            <div :class="{ error: v$.formData.place_of_issue.$errors.length }"></div>
            <!-- <input v-model="state.firstName"> -->
            <label for="place_of_issue" class="m-2">Place of Issue <span class="required">*</span></label>
            <PrimeInputText v-model="formData.place_of_issue" class="w-full" id="place_of_issue" />
            <!-- <PrimeInputText v-model="formData.surname" class="w-full" id="surname" /> -->
            <div class="input-errors" v-for="error of v$.formData.place_of_issue.$errors" :key="error.$uid">
              <div class="error-msg">{{ error.$message }}</div>
            </div>

            <!-- <PrimeInputText v-model="formData.firstname" class="w-full" id="firstname" /> -->
          </div>


          <div v-if="isNationalID" class="field col-12 md:col-6">
            <div :class="{ error: v$.formData.identity_front.$errors.length }"></div>

            <label for="picture" class="form-label">Upload a picture of the front of your Ghana Card <span
                class="required">*</span></label>

            <FileUpload :show-cancel-button="false" :show-upload-button="false" name="demo[]" url="/api/upload"
              @select="onGhanaCardFrontSelect" :multiple="false" :auto="true" accept="image/*" :maxFileSize="1000000">
              <!-- <template #empty>
            <p>Upload Photo.</p>
          </template> -->
            </FileUpload>
            <div class="input-errors" v-for="error of v$.formData.identity_front.$errors" :key="error.$uid">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>

          <div v-if="isNationalID" class="field col-12 md:col-6">
            <div :class="{ error: v$.formData.identity_back.$errors.length }"></div>

            <label for="picture" class="form-label">Upload a picture of the back of your Ghana Card <span
                class="required">*</span></label>

            <FileUpload :show-cancel-button="false" :show-upload-button="false" name="demo[]" url="/api/upload"
              @select="onGhanaCardBackSelect" :multiple="false" :auto="true" accept="image/*" :maxFileSize="1000000">
              <!-- <template #empty>
            <p>Upload Photo.</p>
          </template> -->
            </FileUpload>
            <div class="input-errors" v-for="error of v$.formData.identity_back.$errors" :key="error.$uid">
              <div class="error-msg">{{ error.$message }}</div>
            </div>
          </div>


          <div v-show="isForeigner" class="field col-12 md:col-6">
            <div :class="{ error: v$.formData.residence_permit_no.$errors.length }">
              <!-- <input v-model="state.firstName"> -->
              <label for="residence_permit_no" class="m-2">Residence Permit Number <span
                  class="required">*</span></label>
              <PrimeInputText v-model="formData.residence_permit_no" class="w-full" id="residence_permit_no" />
              <!-- <PrimeInputText v-model="formData.surname" class="w-full" id="surname" /> -->
              <div class="input-errors" v-for="error of v$.formData.residence_permit_no.$errors" :key="error.$uid">
                <div class="error-msg">{{ error.$message }}</div>
              </div>
            </div>
            <!-- <PrimeInputText v-model="formData.firstname" class="w-full" id="firstname" /> -->
          </div>

          <div class="field col-12 md:col-8">
            <!-- <div :class="{ error: v$.formData.uba_customer.$errors.length }"></div> -->
            <label for="residential_status">Residential Status</label>
            <div class="w-full flex">
              <PrimeRadioButton class="ml-2" v-model="formData.residential_status" inputId="residential_status1"
                name="residential_status1" value="Own Residence" />
              <label for="yes" class="ml-2">Own Residence</label>

              <PrimeRadioButton class="ml-2" v-model="formData.residential_status" inputId="residential_status2"
                name="residential_status2" value="Tenant" />
              <label for="not" class="ml-2">Tenant</label>
            </div>


            <!-- <div class="input-errors" v-for="error of v$.formData.uba_customer.$errors" :key="error.$uid">
              <div class="error-msg">{{ error.$message }}</div>
            </div> -->
          </div>
          <div class="field col-12 md:col-6">
            <!-- <div :class="{ error: v$.formData.occupation.$errors.length }"></div> -->
            <label for="occupation">Occupation</label>
            <PrimeInputText v-model="formData.occupation" class="w-full" id="occupation" />
            <!-- <div class="input-errors" v-for="error of v$.formData.occupation.$errors" :key="error.$uid">
              <div class="error-msg">{{ error.$message }}</div>
            </div> -->
          </div>
          <div class="field col-12 md:col-6">
            <!-- <div :class="{ error: v$.formData.gross_income.$errors.length }"></div> -->
            <label for="gross_income">Gross Income</label>
            <PrimeInputText v-model="formData.gross_income" class="w-full" id="gross_income" />
            <!-- <div class="input-errors" v-for="error of v$.formData.gross_income.$errors" :key="error.$uid">
              <div class="error-msg">{{ error.$message }}</div>
            </div> -->
          </div>
          <div class="field col-12 md:col-6">
            <!-- <div :class="{ error: v$.formData.reference.$errors.length }"></div> -->
            <label for="reference">Where Did You Hear About Us?</label>


            <PrimeDropdown class="w-full" v-model="formData.reference" :options="references" optionLabel="label"
              optionValue="value" placeholder="Select" />
            <!-- <div class="input-errors" v-for="error of v$.formData.reference.$errors" :key="error.$uid">
              <div class="error-msg">{{ error.$message }}</div>
            </div> -->
          </div>


          <div class="field col-12 md:col-6">
            <!-- <div :class="{ error: v$.formData.uba_customer.$errors.length }"></div> -->
            <label for="uba_customer">Are you a UBA Customer?</label>


            <div class="w-full flex">
              <PrimeRadioButton class="ml-2" v-model="formData.uba_customer" inputId="uba_customer1" name="Yes"
                value="Yes" />
              <label for="yes" class="ml-2">Yes</label>

              <PrimeRadioButton class="ml-2" v-model="formData.uba_customer" inputId="uba_customer2" name="No"
                value="No" />
              <label for="not" class="ml-2">No</label>
            </div>
            <!-- <div class="input-errors" v-for="error of v$.formData.uba_customer.$errors" :key="error.$uid">
              <div class="error-msg">{{ error.$message }}</div>
            </div> -->
          </div>

          <div v-if="formData.uba_customer == 'Yes'" class="field col-12 md:col-8">
            <label for="account_number">Account Number</label>
            <PrimeInputText v-model="formData.account_number" class="w-full" id="account_number" />

          </div>

        </div>


        <div class="form-buttons">
          <PrimeButton type="button" label="Next" icon="pi pi-chevron-right" @click="nextStep" />
        </div>
      </div>

      <!-- Step Two -->
      <div v-if="currentStep === 1" class="form-step">
        <h3>Step 2 of 4 - Card Details</h3>
        <div class="form-group">
          <div :class="{ error: v$.formData.preferred_name.$errors.length }"></div>

          <label for="preferred_name" class="form-label">Preferred Name on Card <span class="required">*</span></label>
          <PrimeInputText v-model="formData.preferred_name" class="form-control" name="preferred_name"
            id="preferred_name" />
          <div class="input-errors" v-for="error of v$.formData.preferred_name.$errors" :key="error.$uid">
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>

        <div>

          <b>Subscribing for Alerts</b>
          <ol>
            <li>
              Your Card will be linked to your phone to enable you enjoy Mobile banking and receive SMS Alerts on
              transactions on your Card.
            </li>
            <li>
              Please note that appropriate fees with respect to SMS alerts sent to your nominated mobile phone number
              will
              apply.
            </li>
          </ol>

        </div>
        <div class="field col-12 md:col-8">
          <div :class="{ error: v$.formData.cardholder_name.$errors.length }"></div>

          <label for="cardholder_name">Name of Card Holder <span class="required">*</span></label>
          <PrimeInputText v-model="formData.cardholder_name" class="w-full" name="cardholder_name"
            id="cardholder_name" />
          <div class="input-errors" v-for="error of v$.formData.cardholder_name.$errors" :key="error.$uid">
            <div class="error-msg">{{ error.$message }}</div>
          </div>

        </div>
        <div class="field col-12 md:col-8">
          <div :class="{ error: v$.formData.nominated_phone.$errors.length }"></div>

          <label for="nominated_phone">Nominated Phone Number <span class="required">*</span></label>
          <PrimeInputText v-model="formData.nominated_phone" class="w-full" name="nominated_phone"
            id="nominated_phone" />
          <div class="input-errors" v-for="error of v$.formData.nominated_phone.$errors" :key="error.$uid">
            <div class="error-msg">{{ error.$message }}</div>
          </div>
        </div>
        <div :class="{ error: v$.formData.nominated_email.$errors.length }"></div>

        <div class="field col-12 md:col-8">
          <label for="nominated_email">Nominated Email Address <span class="required">*</span></label>
          <PrimeInputText v-model="formData.nominated_email" class="w-full" id="nominated_email" />
          <div class="input-errors" v-for="error of v$.formData.nominated_email.$errors" :key="error.$uid">
            <div class="error-msg">{{ error.$message }}</div>
          </div>

        </div>
        <div class="form-buttons">
          <PrimeButton type="button" label="Back" icon="pi pi-chevron-left" @click="prevStep"
            class="p-button-secondary" />
          <PrimeButton type="button" label="Next" icon="pi pi-chevron-right" @click="nextStep" />
        </div>
      </div>

      <!-- Step Three -->
      <div v-if="currentStep === 2" class="form-step">
        <h3>Step 3 of 4 - Identity Verification</h3>

        <div class="form-group">
          <!-- <h4>Identity Verification</h4> -->
          <b>Instructions</b>
          <ol>
            <li>Ensure there is enough lighting in your room</li>
            <li>Click on the 'Capture' Button to take a selfie making sure your entire face appears in the photo</li>
            <li>Input your Ghana Card number in the text box</li>
            <li>Click on Verify Button to verify your details</li>
           
            <li>Click on 'Reset' Button to reset the camera if verification fails and repeat process</li>
          </ol>
          <video v-show="!captured" ref="video" width="640" height="480" autoplay></video>
          
          <canvas v-show="captured" ref="canvas" width="640" height="480" style="display: none;"></canvas>
          <img ref="photo" alt="Captured Photo" style="display: none;">
          <div class=" flex mt-2">
            <!-- <button type="button" @click="capturePhoto">Capture Photo</button> -->
            <PrimeButton size="sm" class="mr-4 p-button-success" label="Capture" icon="pi pi-camera"
              @click="capturePhoto" />
            <PrimeButton class=" small p-button-warning" label="Reset" icon="pi pi-refresh" @click="resetPhoto" />
            <!-- <button type="button" @click="resetPhoto">Reset</button> -->
          </div>
        </div>
        <div class="form-group">
          <label for="pin" class="form-label">Enter Ghana Card</label>
          <PrimeInputText v-model="formData.gh_card" class="form-control" id="pin" />
        </div>
        <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
        <div v-if="successMessage" class="success-message">{{ successMessage }}</div>
        <div class="form-buttons">
          <PrimeButton type="button" label="Back" icon="pi pi-chevron-left" @click="prevStep"
            class="p-button-secondary" />
          <PrimeButton type="button" :label="buttonText" icon="pi pi-chevron-right" :loading="loading"
            @click="verifyAndProceed" />
        </div>
      </div>

      <!-- Step Four -->
      <div v-if="currentStep === 3" class="form-step">
        <h3>Step 4 of 4 - Terms and Conditions</h3>

        <p>By clicking the submit button, I agree to <a target="_blank"
            href="https://newsite.jcs.com.gh/wp-content/uploads/2024/03/Extracted-pages-from-JCS_UBA-Green-Card-form_Final_9.1.2024.pdf">terms
            and conditions</a> </p>
        <div class="flex flex-wrap gap-3">
          <div class="flex ">
            <PrimeRadioButton v-model="formData.terms_and_conditions" inputId="terms1" name="terms" value="Yes" />
            <label for="ingredient2" class="ml-2">I Accept</label>
          </div>
          <div class="flex ">
            <PrimeRadioButton checked v-model="formData.terms_and_conditions" inputId="terms2" name="terms"
              value="No" />
            <label for="ingredient3" class="ml-2">I do not Accept</label>
          </div>
        </div>


        <div class="form-buttons">
          <PrimeButton type="button" label="Back" icon="pi pi-chevron-left" @click="prevStep"
            class="p-button-secondary" />
          <PrimeButton :disabled="!isAccepted" type="submit" :loading="loading" label="Submit" icon="pi pi-check" />
        </div>
      </div>
    </form>
  </div>
</template>


<script>
/* eslint-disable */
import { useConfirm } from "primevue/useconfirm";
import { useToast } from 'primevue/usetoast';
import { useVuelidate } from '@vuelidate/core'
import { required,requiredIf,email,helpers } from '@vuelidate/validators'
import axios from 'axios';
//const confirm = useConfirm();
const payLink = "https://paystack.com/pay/jcspay";
//const baseUrl = "http://localhost/callback";
const baseUrl = "https://nia.jcs.com.gh";
const phoneCheck=helpers.regex(/^0[2-5]\d{8}$/);
const endpoints = {
  addcustomer: baseUrl + "/verification/addcustomer",
  verifycard: baseUrl + "/verification/card",
  uploadphoto: baseUrl + "/upload/photo"

}

export default {
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      currentStep: 0,
      showVerifyLater:false,
      tries_count:0,
      captured: false,
      loading: false,
      buttonText: "Verify",
      buttonDisable: true,
      showSuccessDialog: false,
      showErrorDialog: false,
      uploadProgress: 0,
      filePath: '',

      steps: [
        { label: 'Step One' },
        { label: 'Step Two' },
        { label: 'Step Three' },
        { label: 'Step Four' }
      ],
      formData: {
        title: '',
        firstname: '',
        middlename: '',
        surname: '',
        residential_address: '',
        digital_address: '',
        city: '',
        country: 'Ghana',
        nationality: '',
        gender: '',
        date_of_birth: '',
        place_of_birth: '',
        email: '',
        mobile: '',
        telephone: '',
        occupation: '',
        gross_income: '',
        reference: '',
        uba_customer: '',
        account_number: '',
        verified: false,
        verified_details: '[]',
        preferred_name: 'Default',
        cardholder_name: 'Default',
        nominated_phone: 'Default',
        nominated_email: 'defaut@jcs.com.gh',
        gh_card: '',
        terms_and_conditions: null,
        photo: "",
        identification_type: "",
        identification_number: "",
        place_of_issue: "",
        identity_front: "",
        identity_back: "",
        residence_permit_no: "--",
        residential_status: "",


      },
      titles: [
        { label: 'Mr', value: 'Mr' },
        { label: 'Mrs', value: 'Mrs' },
        { label: 'Ms', value: 'Ms' },
        { label: 'Dr', value: 'Dr' },
        { label: 'Rev', value: 'Rev' },
        { label: 'Prof', value: 'Prof' }
      ],
      references: [
        { label: 'Social Media', value: 'Social Media' },
        { label: 'Newspaper', value: 'Newspaper' },
        { label: 'TV Ads', value: 'TV Ads' },
        { label: 'Word of Mouth', value: 'Word of Mouth' },
        { label: 'Advertisements', value: 'Advertisements' }
      ],
      termsOptions: [
        { label: 'Accepted', value: 'accepted' },
        { label: 'Not Accepted', value: 'not_accepted' }
      ],
      errorMessage: '',
      successMessage: '',
      verifyLaterMessage:'It seems you are having trouble verifying your identity. You would like to verify it later?',
      countries: [
        {
          "name": "Afghanistan",
          "dial_code": "+93",
          "code": "AF"
        },
        {
          "name": "Aland Islands",
          "dial_code": "+358",
          "code": "AX"
        },
        {
          "name": "Albania",
          "dial_code": "+355",
          "code": "AL"
        },
        {
          "name": "Algeria",
          "dial_code": "+213",
          "code": "DZ"
        },
        {
          "name": "AmericanSamoa",
          "dial_code": "+1684",
          "code": "AS"
        },
        {
          "name": "Andorra",
          "dial_code": "+376",
          "code": "AD"
        },
        {
          "name": "Angola",
          "dial_code": "+244",
          "code": "AO"
        },
        {
          "name": "Anguilla",
          "dial_code": "+1264",
          "code": "AI"
        },
        {
          "name": "Antarctica",
          "dial_code": "+672",
          "code": "AQ"
        },
        {
          "name": "Antigua and Barbuda",
          "dial_code": "+1268",
          "code": "AG"
        },
        {
          "name": "Argentina",
          "dial_code": "+54",
          "code": "AR"
        },
        {
          "name": "Armenia",
          "dial_code": "+374",
          "code": "AM"
        },
        {
          "name": "Aruba",
          "dial_code": "+297",
          "code": "AW"
        },
        {
          "name": "Australia",
          "dial_code": "+61",
          "code": "AU"
        },
        {
          "name": "Austria",
          "dial_code": "+43",
          "code": "AT"
        },
        {
          "name": "Azerbaijan",
          "dial_code": "+994",
          "code": "AZ"
        },
        {
          "name": "Bahamas",
          "dial_code": "+1242",
          "code": "BS"
        },
        {
          "name": "Bahrain",
          "dial_code": "+973",
          "code": "BH"
        },
        {
          "name": "Bangladesh",
          "dial_code": "+880",
          "code": "BD"
        },
        {
          "name": "Barbados",
          "dial_code": "+1246",
          "code": "BB"
        },
        {
          "name": "Belarus",
          "dial_code": "+375",
          "code": "BY"
        },
        {
          "name": "Belgium",
          "dial_code": "+32",
          "code": "BE"
        },
        {
          "name": "Belize",
          "dial_code": "+501",
          "code": "BZ"
        },
        {
          "name": "Benin",
          "dial_code": "+229",
          "code": "BJ"
        },
        {
          "name": "Bermuda",
          "dial_code": "+1441",
          "code": "BM"
        },
        {
          "name": "Bhutan",
          "dial_code": "+975",
          "code": "BT"
        },
        {
          "name": "Bolivia, Plurinational State of",
          "dial_code": "+591",
          "code": "BO"
        },
        {
          "name": "Bosnia and Herzegovina",
          "dial_code": "+387",
          "code": "BA"
        },
        {
          "name": "Botswana",
          "dial_code": "+267",
          "code": "BW"
        },
        {
          "name": "Brazil",
          "dial_code": "+55",
          "code": "BR"
        },
        {
          "name": "British Indian Ocean Territory",
          "dial_code": "+246",
          "code": "IO"
        },
        {
          "name": "Brunei Darussalam",
          "dial_code": "+673",
          "code": "BN"
        },
        {
          "name": "Bulgaria",
          "dial_code": "+359",
          "code": "BG"
        },
        {
          "name": "Burkina Faso",
          "dial_code": "+226",
          "code": "BF"
        },
        {
          "name": "Burundi",
          "dial_code": "+257",
          "code": "BI"
        },
        {
          "name": "Cambodia",
          "dial_code": "+855",
          "code": "KH"
        },
        {
          "name": "Cameroon",
          "dial_code": "+237",
          "code": "CM"
        },
        {
          "name": "Canada",
          "dial_code": "+1",
          "code": "CA"
        },
        {
          "name": "Cape Verde",
          "dial_code": "+238",
          "code": "CV"
        },
        {
          "name": "Cayman Islands",
          "dial_code": "+ 345",
          "code": "KY"
        },
        {
          "name": "Central African Republic",
          "dial_code": "+236",
          "code": "CF"
        },
        {
          "name": "Chad",
          "dial_code": "+235",
          "code": "TD"
        },
        {
          "name": "Chile",
          "dial_code": "+56",
          "code": "CL"
        },
        {
          "name": "China",
          "dial_code": "+86",
          "code": "CN"
        },
        {
          "name": "Christmas Island",
          "dial_code": "+61",
          "code": "CX"
        },
        {
          "name": "Cocos (Keeling) Islands",
          "dial_code": "+61",
          "code": "CC"
        },
        {
          "name": "Colombia",
          "dial_code": "+57",
          "code": "CO"
        },
        {
          "name": "Comoros",
          "dial_code": "+269",
          "code": "KM"
        },
        {
          "name": "Congo",
          "dial_code": "+242",
          "code": "CG"
        },
        {
          "name": "Congo, The Democratic Republic of the Congo",
          "dial_code": "+243",
          "code": "CD"
        },
        {
          "name": "Cook Islands",
          "dial_code": "+682",
          "code": "CK"
        },
        {
          "name": "Costa Rica",
          "dial_code": "+506",
          "code": "CR"
        },
        {
          "name": "Cote d'Ivoire",
          "dial_code": "+225",
          "code": "CI"
        },
        {
          "name": "Croatia",
          "dial_code": "+385",
          "code": "HR"
        },
        {
          "name": "Cuba",
          "dial_code": "+53",
          "code": "CU"
        },
        {
          "name": "Cyprus",
          "dial_code": "+357",
          "code": "CY"
        },
        {
          "name": "Czech Republic",
          "dial_code": "+420",
          "code": "CZ"
        },
        {
          "name": "Denmark",
          "dial_code": "+45",
          "code": "DK"
        },
        {
          "name": "Djibouti",
          "dial_code": "+253",
          "code": "DJ"
        },
        {
          "name": "Dominica",
          "dial_code": "+1767",
          "code": "DM"
        },
        {
          "name": "Dominican Republic",
          "dial_code": "+1849",
          "code": "DO"
        },
        {
          "name": "Ecuador",
          "dial_code": "+593",
          "code": "EC"
        },
        {
          "name": "Egypt",
          "dial_code": "+20",
          "code": "EG"
        },
        {
          "name": "El Salvador",
          "dial_code": "+503",
          "code": "SV"
        },
        {
          "name": "Equatorial Guinea",
          "dial_code": "+240",
          "code": "GQ"
        },
        {
          "name": "Eritrea",
          "dial_code": "+291",
          "code": "ER"
        },
        {
          "name": "Estonia",
          "dial_code": "+372",
          "code": "EE"
        },
        {
          "name": "Ethiopia",
          "dial_code": "+251",
          "code": "ET"
        },
        {
          "name": "Falkland Islands (Malvinas)",
          "dial_code": "+500",
          "code": "FK"
        },
        {
          "name": "Faroe Islands",
          "dial_code": "+298",
          "code": "FO"
        },
        {
          "name": "Fiji",
          "dial_code": "+679",
          "code": "FJ"
        },
        {
          "name": "Finland",
          "dial_code": "+358",
          "code": "FI"
        },
        {
          "name": "France",
          "dial_code": "+33",
          "code": "FR"
        },
        {
          "name": "French Guiana",
          "dial_code": "+594",
          "code": "GF"
        },
        {
          "name": "French Polynesia",
          "dial_code": "+689",
          "code": "PF"
        },
        {
          "name": "Gabon",
          "dial_code": "+241",
          "code": "GA"
        },
        {
          "name": "Gambia",
          "dial_code": "+220",
          "code": "GM"
        },
        {
          "name": "Georgia",
          "dial_code": "+995",
          "code": "GE"
        },
        {
          "name": "Germany",
          "dial_code": "+49",
          "code": "DE"
        },
        {
          "name": "Ghana",
          "dial_code": "+233",
          "code": "GH"
        },
        {
          "name": "Gibraltar",
          "dial_code": "+350",
          "code": "GI"
        },
        {
          "name": "Greece",
          "dial_code": "+30",
          "code": "GR"
        },
        {
          "name": "Greenland",
          "dial_code": "+299",
          "code": "GL"
        },
        {
          "name": "Grenada",
          "dial_code": "+1473",
          "code": "GD"
        },
        {
          "name": "Guadeloupe",
          "dial_code": "+590",
          "code": "GP"
        },
        {
          "name": "Guam",
          "dial_code": "+1671",
          "code": "GU"
        },
        {
          "name": "Guatemala",
          "dial_code": "+502",
          "code": "GT"
        },
        {
          "name": "Guernsey",
          "dial_code": "+44",
          "code": "GG"
        },
        {
          "name": "Guinea",
          "dial_code": "+224",
          "code": "GN"
        },
        {
          "name": "Guinea-Bissau",
          "dial_code": "+245",
          "code": "GW"
        },
        {
          "name": "Guyana",
          "dial_code": "+595",
          "code": "GY"
        },
        {
          "name": "Haiti",
          "dial_code": "+509",
          "code": "HT"
        },
        {
          "name": "Holy See (Vatican City State)",
          "dial_code": "+379",
          "code": "VA"
        },
        {
          "name": "Honduras",
          "dial_code": "+504",
          "code": "HN"
        },
        {
          "name": "Hong Kong",
          "dial_code": "+852",
          "code": "HK"
        },
        {
          "name": "Hungary",
          "dial_code": "+36",
          "code": "HU"
        },
        {
          "name": "Iceland",
          "dial_code": "+354",
          "code": "IS"
        },
        {
          "name": "India",
          "dial_code": "+91",
          "code": "IN"
        },
        {
          "name": "Indonesia",
          "dial_code": "+62",
          "code": "ID"
        },
        {
          "name": "Iran, Islamic Republic of Persian Gulf",
          "dial_code": "+98",
          "code": "IR"
        },
        {
          "name": "Iraq",
          "dial_code": "+964",
          "code": "IQ"
        },
        {
          "name": "Ireland",
          "dial_code": "+353",
          "code": "IE"
        },
        {
          "name": "Isle of Man",
          "dial_code": "+44",
          "code": "IM"
        },
        {
          "name": "Israel",
          "dial_code": "+972",
          "code": "IL"
        },
        {
          "name": "Italy",
          "dial_code": "+39",
          "code": "IT"
        },
        {
          "name": "Jamaica",
          "dial_code": "+1876",
          "code": "JM"
        },
        {
          "name": "Japan",
          "dial_code": "+81",
          "code": "JP"
        },
        {
          "name": "Jersey",
          "dial_code": "+44",
          "code": "JE"
        },
        {
          "name": "Jordan",
          "dial_code": "+962",
          "code": "JO"
        },
        {
          "name": "Kazakhstan",
          "dial_code": "+77",
          "code": "KZ"
        },
        {
          "name": "Kenya",
          "dial_code": "+254",
          "code": "KE"
        },
        {
          "name": "Kiribati",
          "dial_code": "+686",
          "code": "KI"
        },
        {
          "name": "Korea, Democratic People's Republic of Korea",
          "dial_code": "+850",
          "code": "KP"
        },
        {
          "name": "Korea, Republic of South Korea",
          "dial_code": "+82",
          "code": "KR"
        },
        {
          "name": "Kuwait",
          "dial_code": "+965",
          "code": "KW"
        },
        {
          "name": "Kyrgyzstan",
          "dial_code": "+996",
          "code": "KG"
        },
        {
          "name": "Laos",
          "dial_code": "+856",
          "code": "LA"
        },
        {
          "name": "Latvia",
          "dial_code": "+371",
          "code": "LV"
        },
        {
          "name": "Lebanon",
          "dial_code": "+961",
          "code": "LB"
        },
        {
          "name": "Lesotho",
          "dial_code": "+266",
          "code": "LS"
        },
        {
          "name": "Liberia",
          "dial_code": "+231",
          "code": "LR"
        },
        {
          "name": "Libyan Arab Jamahiriya",
          "dial_code": "+218",
          "code": "LY"
        },
        {
          "name": "Liechtenstein",
          "dial_code": "+423",
          "code": "LI"
        },
        {
          "name": "Lithuania",
          "dial_code": "+370",
          "code": "LT"
        },
        {
          "name": "Luxembourg",
          "dial_code": "+352",
          "code": "LU"
        },
        {
          "name": "Macao",
          "dial_code": "+853",
          "code": "MO"
        },
        {
          "name": "Macedonia",
          "dial_code": "+389",
          "code": "MK"
        },
        {
          "name": "Madagascar",
          "dial_code": "+261",
          "code": "MG"
        },
        {
          "name": "Malawi",
          "dial_code": "+265",
          "code": "MW"
        },
        {
          "name": "Malaysia",
          "dial_code": "+60",
          "code": "MY"
        },
        {
          "name": "Maldives",
          "dial_code": "+960",
          "code": "MV"
        },
        {
          "name": "Mali",
          "dial_code": "+223",
          "code": "ML"
        },
        {
          "name": "Malta",
          "dial_code": "+356",
          "code": "MT"
        },
        {
          "name": "Marshall Islands",
          "dial_code": "+692",
          "code": "MH"
        },
        {
          "name": "Martinique",
          "dial_code": "+596",
          "code": "MQ"
        },
        {
          "name": "Mauritania",
          "dial_code": "+222",
          "code": "MR"
        },
        {
          "name": "Mauritius",
          "dial_code": "+230",
          "code": "MU"
        },
        {
          "name": "Mayotte",
          "dial_code": "+262",
          "code": "YT"
        },
        {
          "name": "Mexico",
          "dial_code": "+52",
          "code": "MX"
        },
        {
          "name": "Micronesia, Federated States of Micronesia",
          "dial_code": "+691",
          "code": "FM"
        },
        {
          "name": "Moldova",
          "dial_code": "+373",
          "code": "MD"
        },
        {
          "name": "Monaco",
          "dial_code": "+377",
          "code": "MC"
        },
        {
          "name": "Mongolia",
          "dial_code": "+976",
          "code": "MN"
        },
        {
          "name": "Montenegro",
          "dial_code": "+382",
          "code": "ME"
        },
        {
          "name": "Montserrat",
          "dial_code": "+1664",
          "code": "MS"
        },
        {
          "name": "Morocco",
          "dial_code": "+212",
          "code": "MA"
        },
        {
          "name": "Mozambique",
          "dial_code": "+258",
          "code": "MZ"
        },
        {
          "name": "Myanmar",
          "dial_code": "+95",
          "code": "MM"
        },
        {
          "name": "Namibia",
          "dial_code": "+264",
          "code": "NA"
        },
        {
          "name": "Nauru",
          "dial_code": "+674",
          "code": "NR"
        },
        {
          "name": "Nepal",
          "dial_code": "+977",
          "code": "NP"
        },
        {
          "name": "Netherlands",
          "dial_code": "+31",
          "code": "NL"
        },
        {
          "name": "Netherlands Antilles",
          "dial_code": "+599",
          "code": "AN"
        },
        {
          "name": "New Caledonia",
          "dial_code": "+687",
          "code": "NC"
        },
        {
          "name": "New Zealand",
          "dial_code": "+64",
          "code": "NZ"
        },
        {
          "name": "Nicaragua",
          "dial_code": "+505",
          "code": "NI"
        },
        {
          "name": "Niger",
          "dial_code": "+227",
          "code": "NE"
        },
        {
          "name": "Nigeria",
          "dial_code": "+234",
          "code": "NG"
        },
        {
          "name": "Niue",
          "dial_code": "+683",
          "code": "NU"
        },
        {
          "name": "Norfolk Island",
          "dial_code": "+672",
          "code": "NF"
        },
        {
          "name": "Northern Mariana Islands",
          "dial_code": "+1670",
          "code": "MP"
        },
        {
          "name": "Norway",
          "dial_code": "+47",
          "code": "NO"
        },
        {
          "name": "Oman",
          "dial_code": "+968",
          "code": "OM"
        },
        {
          "name": "Pakistan",
          "dial_code": "+92",
          "code": "PK"
        },
        {
          "name": "Palau",
          "dial_code": "+680",
          "code": "PW"
        },
        {
          "name": "Palestinian Territory, Occupied",
          "dial_code": "+970",
          "code": "PS"
        },
        {
          "name": "Panama",
          "dial_code": "+507",
          "code": "PA"
        },
        {
          "name": "Papua New Guinea",
          "dial_code": "+675",
          "code": "PG"
        },
        {
          "name": "Paraguay",
          "dial_code": "+595",
          "code": "PY"
        },
        {
          "name": "Peru",
          "dial_code": "+51",
          "code": "PE"
        },
        {
          "name": "Philippines",
          "dial_code": "+63",
          "code": "PH"
        },
        {
          "name": "Pitcairn",
          "dial_code": "+872",
          "code": "PN"
        },
        {
          "name": "Poland",
          "dial_code": "+48",
          "code": "PL"
        },
        {
          "name": "Portugal",
          "dial_code": "+351",
          "code": "PT"
        },
        {
          "name": "Puerto Rico",
          "dial_code": "+1939",
          "code": "PR"
        },
        {
          "name": "Qatar",
          "dial_code": "+974",
          "code": "QA"
        },
        {
          "name": "Romania",
          "dial_code": "+40",
          "code": "RO"
        },
        {
          "name": "Russia",
          "dial_code": "+7",
          "code": "RU"
        },
        {
          "name": "Rwanda",
          "dial_code": "+250",
          "code": "RW"
        },
        {
          "name": "Reunion",
          "dial_code": "+262",
          "code": "RE"
        },
        {
          "name": "Saint Barthelemy",
          "dial_code": "+590",
          "code": "BL"
        },
        {
          "name": "Saint Helena, Ascension and Tristan Da Cunha",
          "dial_code": "+290",
          "code": "SH"
        },
        {
          "name": "Saint Kitts and Nevis",
          "dial_code": "+1869",
          "code": "KN"
        },
        {
          "name": "Saint Lucia",
          "dial_code": "+1758",
          "code": "LC"
        },
        {
          "name": "Saint Martin",
          "dial_code": "+590",
          "code": "MF"
        },
        {
          "name": "Saint Pierre and Miquelon",
          "dial_code": "+508",
          "code": "PM"
        },
        {
          "name": "Saint Vincent and the Grenadines",
          "dial_code": "+1784",
          "code": "VC"
        },
        {
          "name": "Samoa",
          "dial_code": "+685",
          "code": "WS"
        },
        {
          "name": "San Marino",
          "dial_code": "+378",
          "code": "SM"
        },
        {
          "name": "Sao Tome and Principe",
          "dial_code": "+239",
          "code": "ST"
        },
        {
          "name": "Saudi Arabia",
          "dial_code": "+966",
          "code": "SA"
        },
        {
          "name": "Senegal",
          "dial_code": "+221",
          "code": "SN"
        },
        {
          "name": "Serbia",
          "dial_code": "+381",
          "code": "RS"
        },
        {
          "name": "Seychelles",
          "dial_code": "+248",
          "code": "SC"
        },
        {
          "name": "Sierra Leone",
          "dial_code": "+232",
          "code": "SL"
        },
        {
          "name": "Singapore",
          "dial_code": "+65",
          "code": "SG"
        },
        {
          "name": "Slovakia",
          "dial_code": "+421",
          "code": "SK"
        },
        {
          "name": "Slovenia",
          "dial_code": "+386",
          "code": "SI"
        },
        {
          "name": "Solomon Islands",
          "dial_code": "+677",
          "code": "SB"
        },
        {
          "name": "Somalia",
          "dial_code": "+252",
          "code": "SO"
        },
        {
          "name": "South Africa",
          "dial_code": "+27",
          "code": "ZA"
        },
        {
          "name": "South Sudan",
          "dial_code": "+211",
          "code": "SS"
        },
        {
          "name": "South Georgia and the South Sandwich Islands",
          "dial_code": "+500",
          "code": "GS"
        },
        {
          "name": "Spain",
          "dial_code": "+34",
          "code": "ES"
        },
        {
          "name": "Sri Lanka",
          "dial_code": "+94",
          "code": "LK"
        },
        {
          "name": "Sudan",
          "dial_code": "+249",
          "code": "SD"
        },
        {
          "name": "Suriname",
          "dial_code": "+597",
          "code": "SR"
        },
        {
          "name": "Svalbard and Jan Mayen",
          "dial_code": "+47",
          "code": "SJ"
        },
        {
          "name": "Swaziland",
          "dial_code": "+268",
          "code": "SZ"
        },
        {
          "name": "Sweden",
          "dial_code": "+46",
          "code": "SE"
        },
        {
          "name": "Switzerland",
          "dial_code": "+41",
          "code": "CH"
        },
        {
          "name": "Syrian Arab Republic",
          "dial_code": "+963",
          "code": "SY"
        },
        {
          "name": "Taiwan",
          "dial_code": "+886",
          "code": "TW"
        },
        {
          "name": "Tajikistan",
          "dial_code": "+992",
          "code": "TJ"
        },
        {
          "name": "Tanzania, United Republic of Tanzania",
          "dial_code": "+255",
          "code": "TZ"
        },
        {
          "name": "Thailand",
          "dial_code": "+66",
          "code": "TH"
        },
        {
          "name": "Timor-Leste",
          "dial_code": "+670",
          "code": "TL"
        },
        {
          "name": "Togo",
          "dial_code": "+228",
          "code": "TG"
        },
        {
          "name": "Tokelau",
          "dial_code": "+690",
          "code": "TK"
        },
        {
          "name": "Tonga",
          "dial_code": "+676",
          "code": "TO"
        },
        {
          "name": "Trinidad and Tobago",
          "dial_code": "+1868",
          "code": "TT"
        },
        {
          "name": "Tunisia",
          "dial_code": "+216",
          "code": "TN"
        },
        {
          "name": "Turkey",
          "dial_code": "+90",
          "code": "TR"
        },
        {
          "name": "Turkmenistan",
          "dial_code": "+993",
          "code": "TM"
        },
        {
          "name": "Turks and Caicos Islands",
          "dial_code": "+1649",
          "code": "TC"
        },
        {
          "name": "Tuvalu",
          "dial_code": "+688",
          "code": "TV"
        },
        {
          "name": "Uganda",
          "dial_code": "+256",
          "code": "UG"
        },
        {
          "name": "Ukraine",
          "dial_code": "+380",
          "code": "UA"
        },
        {
          "name": "United Arab Emirates",
          "dial_code": "+971",
          "code": "AE"
        },
        {
          "name": "United Kingdom",
          "dial_code": "+44",
          "code": "GB"
        },
        {
          "name": "United States",
          "dial_code": "+1",
          "code": "US"
        },
        {
          "name": "Uruguay",
          "dial_code": "+598",
          "code": "UY"
        },
        {
          "name": "Uzbekistan",
          "dial_code": "+998",
          "code": "UZ"
        },
        {
          "name": "Vanuatu",
          "dial_code": "+678",
          "code": "VU"
        },
        {
          "name": "Venezuela, Bolivarian Republic of Venezuela",
          "dial_code": "+58",
          "code": "VE"
        },
        {
          "name": "Vietnam",
          "dial_code": "+84",
          "code": "VN"
        },
        {
          "name": "Virgin Islands, British",
          "dial_code": "+1284",
          "code": "VG"
        },
        {
          "name": "Virgin Islands, U.S.",
          "dial_code": "+1340",
          "code": "VI"
        },
        {
          "name": "Wallis and Futuna",
          "dial_code": "+681",
          "code": "WF"
        },
        {
          "name": "Yemen",
          "dial_code": "+967",
          "code": "YE"
        },
        {
          "name": "Zambia",
          "dial_code": "+260",
          "code": "ZM"
        },
        {
          "name": "Zimbabwe",
          "dial_code": "+263",
          "code": "ZW"
        }
      ],

    };
  },
  validations() {
    return {
      formData: {
        title: { required },
        firstname: { required },
        // middlename: { required },
        surname: { required },
        residential_address: { required },
        digital_address: { required },
        gender: { required },
        city: { required },
        country: { required },
        nationality: { required },
        date_of_birth: { required },
        email: { required, email },
        mobile: { required },
        preferred_name: { required },
        cardholder_name: { required },
        nominated_phone: { required },
        nominated_email: { required,email },
        identification_type: { required },
        identification_number: { required },
        place_of_issue: { required },
        residence_permit_no:{ requiredIf: requiredIf(() => this.formData.identification_type === 'Passport') } ,
        identity_front: { requiredIf: requiredIf(() => this.formData.identification_type  === 'National ID') } ,
        identity_back: { requiredIf: requiredIf(() => this.formData.identification_type  === 'National ID') } ,
        photo: { required },

      }


    }
  },
  computed: {
    isAccepted() {
      return this.formData.terms_and_conditions === 'Yes';
    },
    isNationalID() {
      return this.formData.identification_type === 'National ID';

    },
    isForeigner() {
      return this.formData.identification_type === 'Passport';

    },

    calculateMaxDate() {
      const today = new Date();
      const year = today.getFullYear() - 18;
      const month = today.getMonth();
      const day = today.getDate();
      return new Date(year, month, day);
    }
  },
  methods: {
   

    async nextStep() {
     
      const result = await this.v$.$validate()
      if (!result) {
        // notify user form is invalid
        // alert('All fields marked * are mandatory');
        this.$toast.add({ severity: 'error', summary: 'Info', detail: 'All fields marked * are mandatory', life: 3000 });

        return
      } else {
        if (this.formData.identification_type == 'Passport' && this.currentStep == 1) {
          this.currentStep += 2
        }
        if (this.currentStep < this.steps.length - 1) {
          this.currentStep++;
          if (this.currentStep === 1) {

            this.formData.preferred_name = '';
            this.formData.cardholder_name = '';
            this.formData.nominated_email = '';
            this.formData.nominated_phone = '';
            this.v$.$reset();
          }
          if (this.currentStep === 2) {
            this.startWebcam();
          }
        }
        window.scrollTo({ top: 0, behavior: 'smooth' });
      }



    },
    prevStep() {
      if (this.currentStep === 1) {
        if (this.formData.preferred_name == '') {
          this.formData.preferred_name = 'Default';
        }
        if (this.formData.cardholder_name == '') {
          this.formData.cardholder_name = 'Default';
        }
        if (this.formData.nominated_email == '') {

          this.formData.nominated_email = 'default@jcs.com.gh';
        }
        if (this.formData.nominated_phone == '') {

          this.formData.nominated_phone = 'Default';
        }




      }
      if (this.currentStep > 0) {
        this.currentStep--;

        if (this.currentStep === 2) {
          this.startWebcam();
        }
      }
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    onStepChange(index) {
      this.currentStep = index;
    },
    async handleSubmit() {
      //const url="http://localhost/callback/verification/addcustomer";
      this.loading = true;
      try {
        const response = await axios.post(endpoints.addcustomer, this.formData);
        //alert('submitted');
        // console.log('Form submitted successfully:', response.data);
        // Handle successful submission (e.g., navigate to a success page)
        if (response.data.status == "00") {
          //alert('Form submitted successfully');
          // this.showSuccessDialog = true;
          //window.location.replace("https://jcs.com.gh");
          //this.confirm1();
          this.showSuccessDialog = true;
          this.loading = false;
        }
      } catch (error) {
        this.showErrorDialog = true;
        //alert("error submitting");
        //console.error('Error submitting form:', error);
        // Handle error (e.g., display an error message)
        this.loading = false;
      }
    },
    submitRedirect() {
      this.showSuccessDialog = false;
      window.location.replace(payLink);
    },
    async startWebcam() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        this.$refs.video.srcObject = stream;
      } catch (err) {
        console.error('Error accessing webcam: ', err);
      }
    },
    capturePhoto() {
      const canvas = this.$refs.canvas;
      const context = canvas.getContext('2d');
      context.drawImage(this.$refs.video, 0, 0, canvas.width, canvas.height);
      const dataURL = canvas.toDataURL('image/png');
      this.$refs.photo.src = dataURL;
      this.$refs.photo.style.display = 'block';
      canvas.style.display = 'none';
      this.captured = true;
    },
    resetPhoto() {
      const canvas = this.$refs.canvas;
      const context = canvas.getContext('2d');
      context.clearRect(0, 0, canvas.width, canvas.height);
      this.$refs.photo.style.display = 'none';
      this.errorMessage = '';
      this.successMessage = '';
      this.startWebcam();
      this.captured = false,
      this.buttonText="Verify"
    },
    async verifyAndProceed() {
      this.successMessage = '';
      this.errorMessage = '';
      const pin = this.formData.gh_card;
      if (!pin) {
        this.errorMessage = 'Please enter Ghana Card PIN';
        return;
      } else {
        if (!this.formData.verified) {
          this.buttonText = "Please wait...";
          // let url ='http://localhost/callback/verification/card';
          //let url = 'https://nia.jcs.com.gh/verification/card';

          const canvas = this.$refs.canvas;
          const base64Image = canvas.toDataURL('image/png');

          try {
            this.loading = true;
            //this.$toast.add({ severity: 'info', summary: 'Info', detail: 'testing', life: 3000 });

            const response = await fetch(endpoints.verifycard, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({ pin, image: base64Image })
            });

            // if (!response.ok) {
            //   throw new Error('Network response was not ok');
            // }

            const result = await response.json();
            // console.log(result);


            if (result.code == "00") {
              //this.errorMessage = '';
              this.formData.verified = true;
              this.formData.verified_details = JSON.stringify(result.nia_data);
              this.successMessage = JSON.stringify(result.person);
              this.$toast.add({ severity: 'success', summary: 'Info', detail: result.msg });

              this.buttonText = "Continue";
              //this.nextStep();
            } else 
            {
              this.errorMessage = result.msg;
              this.buttonText = "Verify";

              this.$toast.add({ severity: 'error', summary: 'Info', detail: result.msg,life:3000 });
              this.tries_count+=1;
            }
          } catch (error) {
            this.errorMessage = 'Error verifying PIN and photo. Please try again.';
            console.error('Error:', error);
            this.buttonText = "Verify";
            this.tries_count+=1;

          }
          this.loading = false;
          if(this.tries_count>=3){

            this.showVerifyLater=true;
          }
        } else {
          this.nextStep();
        }
      }


    },
    confirm1() {
      this.$confirm.require({
        message: 'Form submitted successfully',
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        rejectClass: 'p-button-secondary p-button-outlined',
        rejectLabel: 'OK',
        acceptLabel: 'OK',
        accept: () => {
          //this.$toast.add({ severity: 'info', summary: 'Confirmed', detail: 'You have accepted', life: 3000 });
          window.location.replace("https://jcs.com.gh");

        },
        // reject: () => {
        //     this.$toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
        // }
      });
    },
    confirm2() {
      this.$confirm.require({
        message: 'Do you want to delete this record?',
        header: 'Danger Zone',
        icon: 'pi pi-info-circle',
        rejectLabel: 'Cancel',
        acceptLabel: 'Delete',
        rejectClass: 'p-button-secondary p-button-outlined',
        acceptClass: 'p-button-danger',
        accept: () => {
          this.$toast.add({ severity: 'info', summary: 'Confirmed', detail: 'Record deleted', life: 3000 });
        },
        reject: () => {
          this.$toast.add({ severity: 'error', summary: 'Rejected', detail: 'You have rejected', life: 3000 });
        }
      });
    },

    onAdvancedUpload() {
      this.$toast.add({ severity: 'info', summary: 'Success', detail: 'File Uploaded', life: 3000 });
    },

    async onFileSelect(event) {
      const file = event.files[0];
      if (!file) return;

      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await axios.post(endpoints.uploadphoto+"/profile", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: progressEvent => {
            this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          }
        });

        // Assuming the API returns an object with the file path
        // this.filePath = response.data.filePath;
        // console.log(response.data);
        this.formData.photo = response.data;
      } catch (error) {
        console.error('Error uploading file:', error);
        // Handle error accordingly
      } finally {
        // Reset the upload progress after completion
        this.uploadProgress = 0;
      }
    },
    async onGhanaCardFrontSelect(event) {
      const file = event.files[0];
      if (!file) return;

      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await axios.post(endpoints.uploadphoto + '/card_front', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: progressEvent => {
            this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          }
        });

        // Assuming the API returns an object with the file path
        // this.filePath = response.data.filePath;
        // console.log(response.data);
        this.formData.identity_front = response.data;
      } catch (error) {
        console.error('Error uploading file:', error);
        // Handle error accordingly
      } finally {
        // Reset the upload progress after completion
        this.uploadProgress = 0;
      }
    },
    async onGhanaCardBackSelect(event) {
      const file = event.files[0];
      if (!file) return;

      const formData = new FormData();
      formData.append('file', file);

      try {
        const response = await axios.post(endpoints.uploadphoto + '/card_back', formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          },
          onUploadProgress: progressEvent => {
            this.uploadProgress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          }
        });

        // Assuming the API returns an object with the file path
        // this.filePath = response.data.filePath;
        // console.log(response.data);
        this.formData.identity_back = response.data;
      } catch (error) {
        console.error('Error uploading file:', error);
        // Handle error accordingly
      } finally {
        // Reset the upload progress after completion
        this.uploadProgress = 0;
      }
    },

    passportClick() {
      this.formData.identification_number = ''
      this.formData.residence_permit_no = ''
      this.formData.identity_front = '-';
      this.formData.identity_back = '-'
    },

    nationalIDClick() {
      this.formData.identification_number = ''
      this.formData.residence_permit_no = '-'
      this.formData.identity_front = '';
      this.formData.identity_back = ''
    },

    retryCapture(){
      this.showVerifyLater=false;
      this.tries_count=0;
      this.resetPhoto();

    },
    verifyLater(){
      this.showVerifyLater=false;
      this.tries_count=0;
      this.nextStep();
    }


  }


};
</script>



<style scoped>
.container {
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  background-color: rgba(98, 148, 75, 0.411);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.text-center {
  text-align: center;
}

.mb-4 {
  margin-bottom: 1.5rem;
}

.mr-4 {
  margin-right: 0.5rem;
}

.mt-5 {
  margin-top: 3rem;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.form-step {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.form-row {
  display: flex;
  gap: 20px;
}

.form-label {
  margin-bottom: 0.5rem;
}

.form-radio-group {
  display: flex;
  align-items: center;
  gap: 10px;
}

.radio-label {
  margin-left: 0.5rem;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

video {
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-bottom: 10px;
}

canvas {
  display: none;
}

img {
  border: 1px solid #ccc;
  border-radius: 10px;
  margin-top: 10px;
}

.error-message {
  color: red;
  margin-top: 10px;
  font-size: medium;
  font-weight: bold;
}

.success-message {
  color: rgba(29, 141, 54, 0.877);
  margin-top: 10px;
  font-size: medium;
  font-weight: bold;
}

video {
  max-width: 100%;
  height: auto;
}

.error-msg {
  margin: 0.2rem;
  color: rgb(218, 61, 61);
  font-size: 0.7rem;
  font-weight: 700
}

.required {
  color: rgb(218, 61, 61);
}

label {
  color: rgb(56, 56, 53);
  font-size: 0.8rem;
  font-weight: 800;
  margin-bottom: 0.2rem;

}

h3 {
  color: rgb(56, 56, 53);
}
</style>
